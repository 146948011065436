// Generated by Framer (d7e8e3e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);

const cycleOrder = ["frbbdOONQ", "lQ3uhRb3e"];

const serializationHash = "framer-s5yMh"

const variantClassNames = {frbbdOONQ: "framer-v-pajdf6", lQ3uhRb3e: "framer-v-1sqsyj6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {no: "lQ3uhRb3e", yes: "frbbdOONQ"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "frbbdOONQ"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "frbbdOONQ", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "lQ3uhRb3e") return false
return true
}

const isDisplayed1 = () => {
if (baseVariant === "lQ3uhRb3e") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-s5yMh", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-pajdf6", className)} data-framer-name={"yes"} layoutDependency={layoutDependency} layoutId={"frbbdOONQ"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({lQ3uhRb3e: {"data-framer-name": "no"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<motion.div className={"framer-y8f1zr-container"} layoutDependency={layoutDependency} layoutId={"DSO_K0XLG-container"}><Material color={"var(--token-1e6aa030-b9a6-4d7d-9eed-12ec3ce25c6d, rgb(46, 50, 56)) /* {\"name\":\"GrayDark2\"} */"} height={"100%"} iconSearch={"Home"} iconSelection={"Check"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"DSO_K0XLG"} layoutId={"DSO_K0XLG"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div>)}{isDisplayed1() && (<motion.div className={"framer-im3h3u-container"} layoutDependency={layoutDependency} layoutId={"mXS5dGOkR-container"}><Material color={"var(--token-b16a7865-924d-449a-9a9b-b11ba99dad5b, rgb(92, 100, 112)) /* {\"name\":\"Gray-Dark1\"} */"} height={"100%"} iconSearch={"Home"} iconSelection={"Close"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"mXS5dGOkR"} layoutId={"mXS5dGOkR"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div>)}</motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-s5yMh [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-s5yMh .framer-1x60hn3 { display: block; }", ".framer-s5yMh .framer-pajdf6 { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-s5yMh .framer-y8f1zr-container, .framer-s5yMh .framer-im3h3u-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"lQ3uhRb3e":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerqrlEMbIbc: React.ComponentType<Props> = withCSS(Component, css, "framer-s5yMh") as typeof Component;
export default FramerqrlEMbIbc;

FramerqrlEMbIbc.displayName = "Check";

FramerqrlEMbIbc.defaultProps = {height: 24, width: 24};

addPropertyControls(FramerqrlEMbIbc, {variant: {options: ["frbbdOONQ", "lQ3uhRb3e"], optionTitles: ["yes", "no"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerqrlEMbIbc, [...MaterialFonts])